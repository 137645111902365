import React from 'react'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ClaimOrderDialog from './ClaimOrderDialog'
import ShareLicensesDialog from './ShareLicensesDialog'
import ClaimLicenseDialog from './ClaimLicenseDialog'
import CssBaseline from '@material-ui/core/CssBaseline'
import DataTable from './common/DataTable'
import LoginPanel from './common/LoginPanel'
import { useLogin } from './common/LoginContext'
import { useMissionPacks } from './common/MissionPackContext'
import { getSim, postSim, setErrorCb, adjustColor } from './common/utils'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import MenuOpenIcon from '@material-ui/icons/MenuOpen'
import CloseIcon from '@material-ui/icons/Close'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import ActionPanel from './ActionPanel'
import QuickFilter from './common/QuickFilter'
import DashboardIcon from './dashimg.png'
import FiriaLabsBanner from './common/firialabsbanner.png'
import LoadingErrorPage from './common/LoadingErrorPage'
import ActivateLicenseDialog from './ActivateLicenseDialog'
import ClaimButton from './ClaimButton'
import CustomerSupportFab from './common/CustomerSupportFab'
import { UserSessionOverwrittenPage } from './common/UserSessionOverwrittenPage'

export const rightDrawerWidth = '23rem'
export const appHeaderHeight = '196px'
export const actionPanelHeaderHeight = '196px'
export const internalAppHeaderHeight = '4rem'

// app header = 4em, claim license banner = 8.5em, add license = 7em

const useStyles = makeStyles(theme => ({
  appRoot: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    textAlign: 'center',
    backgroundColor: theme.palette.custom.background,
  },
  appHeader: {
    backgroundColor: 'white',
    display:'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerItemLeft: {
    flex: 1,
    padding: '0 15px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  headerItemRight: {
    flex: 1,
    padding: '0 15px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  headerItemCenter: {
    flexGrow: 1,
    display:'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  headerImage: {
    alignSelf: 'center',
    height:'2.5rem',
    marginTop: '0.75rem',
    marginRight: 5,
    float: 'left',
  },
  headerImage2: {
    alignSelf: 'center',
    height:internalAppHeaderHeight,
    margin: 'auto',
  },
  headerText: {
    color: 'black',
    display:'flex',
    alignItems: 'center',
    fontSize: '0.9rem',
  },
  claimBannerImgRow: {
    display: 'flex',
    width: '100%',
  },
  claimBannerSpacers: {
    flexGrow: 1,
  },
  claimLicenseButton: {
    backgroundColor: theme.palette.custom.secondary,
    color: 'black',
    whiteSpace: 'nowrap',
    minWidth: 'max-content',
    '&:hover': {
      backgroundColor: adjustColor(theme.palette.custom.secondary, -10),
    },
  },
  claimLicenseText: {
    textAlign: 'left',
    width: '100%',
    color: 'black',
    marginBottom: '0.7rem',
    fontSize: '1.3rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  addLicenseKeyText: {
    color: 'black',
    fontSize: '1.3rem',
    margin: 'auto',
    marginRight: '2rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  storeImage: {
    height: '2rem',
    margin: 'auto',
    marginRight: '2.5rem',
  },
  addLicenseButton: {
    backgroundColor: theme.palette.custom.primary,
    color: 'black',
    whiteSpace: 'nowrap',
    height: 44,
    minWidth: 'max-content',
    '&:hover': {
      backgroundColor: adjustColor(theme.palette.custom.primary, -10),
    },
  },
  addLicenseKeyBanner: {
    height: '6.5rem',
    minHeight: '6.5rem',
    maxHeight: '6.5rem',
    padding: '2rem',
    width: '100%',
    borderTop: '1px solid black',
    backgroundColor: '#92D050',
    display: 'flex',
  },
  appBody: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  bodyHeader: {
    backgroundColor: theme.palette.custom.primary,
    border: `1px solid ${theme.palette.custom.quaternary}`,
    borderBottom: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  bodyContent: {
    flexGrow: 1,
    display:'flex',
    flexDirection: 'column',
  },
  breadCrumbs: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableNoShift: {
    transition: theme.transitions.create('max-width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    maxWidth: '100vw',
    height: '100%',
  },
  tableShifted: {
    transition: theme.transitions.create('max-width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    maxWidth: '100vw',
    height: '100%',
  },
  mpContent: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    height: '100%',
  },
  mpShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: rightDrawerWidth,
    height: '100%',
  },
  paper: {
    width:'100%',
    height:'100%',
    display:'flex',
    backgroundColor: 'white', // this sets the color behind the rows
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      height: 45,
      '& fieldset': {
        color: theme.palette.custom.quaternary,
      },
      '&.Mui-focused fieldset': {
        color: theme.palette.custom.quaternary,
      },
    },
    '& .MuiInputLabel-root': {
      top: -5,
    },
    '& .MuiInputLabel-shrink':{
      top: 0,
      color: theme.palette.custom.quaternary,
    },
  },
  button: {
    backgroundColor: theme.palette.custom.accent,
  },
  rowFlex: {
    display:'flex',
    flexDirection:'row',
  },
  headerDivider: {
    width:'100%',
    paddingTop: 12,
    paddingLeft: 10,
  },
  headerItem: {
    width: '30%',
    padding: '0px 15px',
    display:'flex',
    justifyContent: 'flex-start',
    alignItems:'center',
  },
  actionsPaper: {
    height: '100px',
    backgroundColor: theme.palette.custom.primary,
    display:'flex',
  },
  actionsContainer: {
    width: '280px',
    display:'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    borderTop: `1px solid ${theme.palette.custom.quaternary}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '56px',
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.custom.quaternary}`,
    width: '100%',
  },
}))

const columns = (missionPacks, ActivateLicenseButton, setSelectedActivateLicenseData) => {
  return (
    [
      { field: 'id',
        headerName: 'License Key',
        flex: 3,
      },
      { field: 'missionPackId',
        headerName: 'Product',
        valueGetter: (params) => {
          var missionPackId = params.row.missionPackId
          try {
            missionPackId = missionPacks.find(pack => pack.id === params.row.missionPackId).title
          } catch (err){
          }
          return missionPackId
        },
        flex: 2,
      },
      {
        field: 'type',
        headerName: 'License Type',
        flex: 1,
      },
      {
        field: 'licenseTerm',
        headerName: 'License Term',
        flex: 1,
        valueGetter: (params) => {
          return params.row.expires ? params.row.licenseTerm : 'Perpetual'
        },
      },
      {
        field: 'expiration',
        headerName: 'Expires On',
        flex: 2,
        clickable:false,
        renderCell: (params) => {
          return !params.row.expires ? <div style={{fontSize: 25}}>∞</div> : params.row.expiration ?
            new Date(params.row.expiration?._seconds * 1000).toLocaleString() :
            <ActivateLicenseButton onClick={() => setSelectedActivateLicenseData(params)} style={{ flex: 1 }}/>
        },
      },
      {
        field: 'seats',
        headerName: 'Seats',
        flex: 1,
      },
      {
        field: 'active/taken',
        headerName: 'Active/Taken',
        flex: 1,
        valueGetter: (params) => {
          if (params.row.type === 'Assigned'){
            return params.row.users.length
          }
          return params.row.activeUsers.length
        },
      },
    ]
  )
}

const LinkButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText('#636363'),
    backgroundColor: '#636363',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#828282',
    },
  },
}))(Button)

function AppContent() {
  const classes = useStyles()
  const [licenseRows, setLicenseRows] = React.useState([])
  const [errorState, setErrorState] = React.useState(false)
  const [filteredLicenseRows, setFilteredLicenseRows] = React.useState(null)
  const [showActionPanel, setShowActionPanel] = React.useState('settings')
  const [claimOrderDialog, setClaimOrderDialog] = React.useState(false)
  const [shareLicensesDialog, setShareLicensesDialog] = React.useState(false)
  const [selectedRows, setSelectedRows] = React.useState([])
  const [licenseTableStatus, setLicenseTableStatus] = React.useState('loggedOut')
  const [requestLicenseFetchStatus, setRequestLicenseFetchStatus] = React.useState(false)
  const [requestLicenseData, setRequestLicenseData] = React.useState({adminUserList: [], userList: []})
  const [orderEmailData, setOrderEmailData] = React.useState([])
  const [claimLicenseDialog, setClaimLicenseDialog] = React.useState(false)
  const [getOrdersByEmailStatus, setGetOrdersByEmailStatus] = React.useState(false)
  const [tableParams, setTableParams] = React.useState({
    headerHeight: null,
    pageSize: 0,
  })
  const [selectedActivateLicenseData, setSelectedActivateLicenseData] = React.useState(null)
  const [loginState] = useLogin()
  const { missionPacks } = useMissionPacks()

  setErrorCb(()=>setErrorState(true))

  const ActivateLicenseButton = ({ onClick, style }) => {
    return <Button
      variant='contained'
      className={classes.claimLicenseButton}
      size='large'
      style={style}
      onClick={onClick}
    >
    Activate
    </Button>
  }


  const getLicenses = React.useCallback(async () => {
    setLicenseTableStatus('waiting')
    const resp = await getSim('getLicenses', loginState?.user)
    if (resp.status === 200){
      const respData = (await resp.json()).message
      setLicenseRows(respData.filter(license => !license?.manuallyDeactivated))
      setLicenseTableStatus('success')
    } else if (resp.status === 422){
      setLicenseTableStatus('error')
    }
  }, [loginState])

  React.useEffect(() => {
    if (loginState?.user){
      getLicenses()
    }
  }, [loginState, getLicenses])

  React.useEffect(() => {
    const getOrdersByEmail = async () => {
      setGetOrdersByEmailStatus('waiting')
      const resp = await getSim('getOrdersByEmail', loginState?.user)
      if (resp.status === 200){
        setOrderEmailData((await resp.json())?.message)
        setGetOrdersByEmailStatus('success')
      } else {
        setOrderEmailData([])
        setGetOrdersByEmailStatus('failed')
      }
    }

    if (loginState?.user){
      getOrdersByEmail()
    }
  }, [loginState, claimOrderDialog])

  const claimOrder = async (orderNumbers) => {
    const resp = await postSim('claimOrder', loginState?.user, {orderNumbers: orderNumbers})
    return resp
  }

  const requestOrder = async (orderNumber) => {
    const resp = await getSim(`requestOrder/${orderNumber}`, loginState?.user)
    return resp
  }

  const getLicenseData = async (licenseId) => {
    const resp = await getSim(`licenses/${licenseId}`, loginState?.user)
    return resp
  }

  const claimLicenseKey = async (licenseId) => {
    const resp = await postSim('claimLicenseKey', loginState?.user, {licenseId: licenseId})
    return resp
  }

  const activateLicense = async (licenseId) => {
    const resp = await postSim('activateLicense', loginState?.user, {licenseId: licenseId})
    return resp
  }

  const shareLicenses = async (recipientEmail, licenseIds) => {
    const resp = await postSim('shareLicenses', loginState?.user,
      {
        recipientEmail: recipientEmail,
        licenseIdList: licenseIds,
      })
    return resp
  }

  const removeMemberFromLicense = async (licenseId, memberEmail) => {
    const resp = await postSim('removeMemberFromLicense', loginState?.user,
      {
        licenseId: licenseId,
        memberEmail: memberEmail,
      })
    return resp
  }

  const removeAdminFromLicense = async (licenseId, adminEmail) => {
    const resp = await postSim('removeAdminFromLicense', loginState?.user,
      {
        licenseId: licenseId,
        adminEmail: adminEmail,
      })
    return resp
  }

  const addClaimedLicenses = (newLicenses) => {
    setLicenseRows([...licenseRows, ...newLicenses])
  }

  const toggleSettingsActionMenu = () => {
    if (!showActionPanel) {
      setShowActionPanel('settings')
    } else {
      setShowActionPanel(false)
    }
  }

  const baseBreadCrumb = {
    label: 'My Licenses',
    onClick: () => {},
  }

  const setLicenseData = async (licenseId) => {
    setShareLicensesDialog(true)
    setRequestLicenseFetchStatus('waiting')
    const resp = await getLicenseData(licenseId)
    if (resp.status === 200){
      setRequestLicenseFetchStatus('success')
      const data = (await resp.json())
      setRequestLicenseData({adminUserList: data.admins, userList: data.users})
      return
    }
    setRequestLicenseFetchStatus('failed')
  }

  const onRowClick = async (row) => {
    if (row.field === 'expiration' && row.row.expires && (!row.row.expiration || row.row.expiration === '')) {
      return
    }
    setSelectedRows([row])
    setLicenseData(row.id)
  }

  React.useEffect(() => {
    if (!shareLicensesDialog){
      setRequestLicenseData({adminUserList: [], userList: []})
    }
  }, [shareLicensesDialog])

  // const [breadCrumbs, setBreadCrumbs] = React.useState([baseBreadCrumb])
  const [breadCrumbs] = React.useState([baseBreadCrumb])

  if (loginState.displayUserLoggedOutMessage) {
    return <UserSessionOverwrittenPage />
  }

  return (
    <>
      {errorState ? <LoadingErrorPage />:
        <div className={classes.appRoot}>
          <Paper square elevation={5} className={classes.appHeader}>
            <div className={classes.headerItemLeft}>
              <div style={{verticalAlign: 'center', height: internalAppHeaderHeight}}>
                <img className={classes.headerImage} alt={''} src={DashboardIcon}/>
                <Typography variant='h6' className={classes.headerText} style={{height: '100%'}}>
                  License Portal
                </Typography>
              </div>
              <div style={{flexGrow: 1}}></div>
            </div>
            <div className={classes.headerItemCenter}>
              <img className={classes.headerImage2} alt={''} src={FiriaLabsBanner}/>
            </div>
            <div className={classes.headerItemRight}>
              <div style={{height: internalAppHeaderHeight}}>
                <div style={{float: 'right', display: 'flex', alignItems: 'center', height: '100%'}}>
                  <LoginPanel />
                </div>
              </div>
              <div style={{flexGrow: 1}}></div>
            </div>
          </Paper>
          <div className={classes.claimLicenseBanner}
            style={{
              backgroundColor: '#92D050',
              borderTop: '1px solid black',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              padding: '20px 40px',
            }}
          >
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
              <Typography
                variant={'h5'}
                // className={classes.claimLicenseText}
              >
                {'Looking for your license from a recent purchase?'}
              </Typography>
              <div style={{height: 15}}/>
              <ClaimButton
                unclaimedAssociatedOrders={orderEmailData.length}
                openClaimOrderDialog={() => setClaimOrderDialog(true)}
              />
            </div>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
              <Typography
                variant={'h5'}
                className={classes.addLicenseKeyText}
              >
                {'Did a license owner share a license key with you?'}
              </Typography>
              <div style={{height: 15}}/>
              <Button
                variant='contained'
                className={classes.addLicenseButton}
                onClick={() => {
                  setClaimLicenseDialog(true)
                }}
              >
                {'ADD LICENSE KEY'}
              </Button>
            </div>
          </div>
          <div className={classes.appBody}>
            <div className={showActionPanel ? classes.mpShift : classes.mpContent}>
              <div className={showActionPanel ? classes.tableShifted : classes.tableNoShift}>
                <div style={{display:'flex', width:'100%', height:'100%'}}>
                  <div style={{width:'100%', display:'flex', flexDirection:'column', minHeight: '25.7rem'}}>
                    <div className={classes.bodyHeader}>
                      <div style={{width: '20%'}}></div>
                      <div className={classes.breadCrumbs}>
                        <Breadcrumbs separator='›'>
                          {breadCrumbs.map((crumb, index) => {
                            var crumbComponent = null
                            if (breadCrumbs.length > index + 1){
                              crumbComponent =
                        <LinkButton onClick={crumb.onClick} key={index}>
                          {crumb.label}
                        </LinkButton>
                            } else {
                              crumbComponent =
                      <Typography key={index} style={{color: 'black', fontWeight: 500, fontSize: '0.9em'}}>
                        {crumb.label}
                      </Typography>
                            }
                            return crumbComponent
                          })}
                        </Breadcrumbs>
                      </div>
                      <div className={classes.buttonContainer}>
                        <div className={classes.buttonBorder}></div>
                        <div className={classes.headerStyle}>
                          { !showActionPanel ?
                            <Tooltip
                              title='Open Filter Panel'
                              placement='top'
                              arrow
                            >
                              <div>
                                <IconButton
                                  onClick={toggleSettingsActionMenu}
                                  style={{color: 'black'}}
                                >
                                  <MenuOpenIcon />
                                </IconButton>
                              </div>
                            </Tooltip> : null
                          }
                        </div>
                      </div>
                    </div>
                    <div className={classes.bodyContent}>
                      <Paper square className={classes.paper}>
                        <DataTable
                          appHeaderHeight={appHeaderHeight}
                          defaultSort={{field: 'missionPackId', sort: 'asc'}}
                          tableContent='licenses'
                          addItemComment='Click the CLAIM LICENSE button to find a new license!'
                          status={licenseTableStatus}
                          rows={filteredLicenseRows ? filteredLicenseRows : licenseRows}
                          columns={columns(missionPacks, ActivateLicenseButton, setSelectedActivateLicenseData)}
                          rowsPerPageOptions={[10]}
                          autoPageSize
                          disableMultipleSelection
                          // onRowClick={onRowClick}
                          onCellClick={onRowClick}
                          tableParams={tableParams}
                          setTableParams={setTableParams}
                          // checkboxSelection={selectLicensesMode}
                          disableColumnMenu
                          // onSelectionModelChange={rows => setSelectedRows(rows)}
                        />
                      </Paper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ActionPanel
            title={'Filters'}
            open={showActionPanel !== false}
            handleClose={() => {
              setShowActionPanel(false)
            }}
          >
            {showActionPanel === 'settings' ?
              <>
                <div style={{borderTop: '1px solid #444444', display: 'flex'}}>
                  <div style={{flex: 1}}></div>
                  <div style={{flexGrow: 2, textAlign: 'center', margin: 'auto'}}>
                    <Typography style={{color: 'black', fontWeight: 'bold'}}>
                      {'Filters'}
                    </Typography>
                  </div>
                  <div style={{flex: 1}}>
                    <Tooltip
                      title='Close Filter Panel'
                      placement='top'
                      arrow
                      style={{float: 'right'}}
                    >
                      <div>
                        <IconButton
                          onClick={toggleSettingsActionMenu}
                          style={{color: 'black', padding: '12px'}}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <QuickFilter
                  height={'2rem'}
                  rows={licenseRows}
                  setFilteredRows={setFilteredLicenseRows}
                />
              </> :
              null}
          </ActionPanel>
          <ClaimOrderDialog
            open={claimOrderDialog}
            handleClose={() => setClaimOrderDialog(false)}
            addClaimedLicenses={addClaimedLicenses}
            claimOrder={claimOrder}
            requestOrder={requestOrder}
            classes={classes}
            packList={missionPacks}
            getLicenses={getLicenses}
            orderEmailData={orderEmailData}
            getOrdersByEmailStatus={getOrdersByEmailStatus}
            user={loginState?.user}
          />
          <ClaimLicenseDialog
            requestLicenseFetchStatus={requestLicenseFetchStatus}
            claimLicenseKey={claimLicenseKey}
            open={claimLicenseDialog}
            handleClose={() => setClaimLicenseDialog(false)}
            selectedRows={selectedRows.length}
            shareLicenses={shareLicenses}
            getLicenses={getLicenses}
            classes={classes}
          />
          <ShareLicensesDialog
            activateLicense={activateLicense}
            user={loginState?.user}
            requestLicenseFetchStatus={requestLicenseFetchStatus}
            requestLicenseData={requestLicenseData}
            claimLicenseKey={claimLicenseKey}
            open={shareLicensesDialog}
            handleClose={() => setShareLicensesDialog(false)}
            selectedLicense={selectedRows.length !== 0 ? selectedRows[0] : []}
            shareLicenses={shareLicenses}
            classes={classes}
            setLicenseData={setLicenseData}
            removeMemberFromLicense={removeMemberFromLicense}
            removeAdminFromLicense={removeAdminFromLicense}
            setSelectedActivateLicenseData={setSelectedActivateLicenseData}
            ActivateLicenseButton={ActivateLicenseButton}
          />
          <ActivateLicenseDialog
            activateLicense={activateLicense}
            licenseData={selectedActivateLicenseData}
            closeDialog={() => setSelectedActivateLicenseData(null)}
            missionPacks={missionPacks}
            getLicenses={getLicenses}
          />
          <CustomerSupportFab />
        </div>}
    </>
  )
}

function App() {
  return (
    <>
      <CssBaseline />
      <AppContent />
    </>
  )
}

export default App
